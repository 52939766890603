import { isMobile } from 'mobile-device-detect';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { queryParams } from '../../utils';

import InfoSvg from './icons/InfoSvg';
import AutoplayOffSvg from './icons/autoplayOffSvg';
import AutoplayOnSvg from './icons/autoplayOnSvg';
import BetSvg from './icons/betSvg';
import BurgerSvg from './icons/burgerSvg';
import ConfirmSvg from './icons/confirmSvg';
import HistorySvg from './icons/historySvg';
import LobbySvg from './icons/lobbySvg';
import SkipSvg from './icons/skipSvg';
import SoundSvg from './icons/soundSvg';
import SpinSvg from './icons/spinSvg';
import StartSvg from './icons/startSvg';
import TurbospinOffSvg from './icons/turbospinOffSvg';
import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className={styles.title}>{t('infoButtonBehaviorsTitle')}</h1>
      <div className={styles.buttonBehaviors}>
        <div className={`${styles.buttonBehaviors__item} ${styles.start}`}>
          <StartSvg />
          {t('infoButtonBehaviorsStart')}
        </div>
        <div className={`${styles.buttonBehaviors__item} ${styles.spin}`}>
          <SpinSvg />
          {t('infoButtonBehaviorsSpin')}
        </div>
        <div className={`${styles.buttonBehaviors__item} ${styles.stop}`}>
          <SkipSvg />
          {t('infoButtonBehaviorsStop')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <AutoplayOnSvg />
          {t('infoButtonBehaviorsAutoPlay')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <AutoplayOffSvg />
          {t('infoButtonBehaviorsAutoPlayStop')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <BetSvg />
          {t('infoButtonBehaviorsBetSettings')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <TurbospinOffSvg />
          {t('infoButtonBehaviorsTurbo')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <BurgerSvg />
          {t('infoButtonBehaviorsMenu')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <InfoSvg />
          {t('infoButtonBehaviorsMenuOpen')}
        </div>
      </div>

      <h1 className={styles.subtitle}>{t('infoButtonBehaviorsBetSettingsTitle')}</h1>
      <p
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsBetSettingsText'),
        }}
      />
      <h1 className={styles.subtitle}>{t('infoButtonBehaviorsAutoPlaySettingsTitle')}</h1>
      <p
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsAutoPlaySettingsText'),
        }}
      />
      <div className={`${styles.row} ${styles.center}`}>
        <div className={styles.image}>
          <ConfirmSvg />
          <p className={styles.image__text}>{t('infoButtonBehaviorsAutoPlayStart')}</p>
        </div>
      </div>

      <h1 className={styles.subtitle}>{t('infoButtonBehaviorsMenuTitle')}</h1>
      <div className={styles.buttonBehaviors}>
        <div className={styles.buttonBehaviors__item}>
          <SoundSvg />
          {t('infoButtonBehaviorsSound')}
        </div>
        {(lobbyBtnAvailable(queryParams) || isMobile) && (
          <div className={styles.buttonBehaviors__item}>
            <LobbySvg />
            {t('infoButtonBehaviorsMenuLobby')}
          </div>
        )}
        <div className={styles.buttonBehaviors__item}>
          <HistorySvg />
          {t('infoButtonBehaviorsMenuHistory')}
        </div>
      </div>
      <p className={`${styles.p} ${styles.center}`}>{t('infoButtonBehaviorsDescription')}</p>
    </>
  );
};

export default ButtonBehaviors;
