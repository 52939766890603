import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, SlotId } from '../config';
import {
  BetBonusReward,
  BetReward,
  EventTypes,
  GameMode,
  IChainData,
  IChainInitialPayLines,
  ISettledBet,
  ReelSet,
  UserBonus,
  reelSets,
} from '../global.d';
import { setCoinValue } from '../gql';
import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeaturePurchased,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringWinCountUpAnimation,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setPrevSpinResult,
  setReelSetId,
  setScatterPositions,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetChainData,
  setUserLastBetChainInitialPayLines,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, isStoppedGql, slotBetGql } from '../gql/query';
import {
  getGameModeByBonusId,
  getRandomNumber,
  getSpinResult,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinMode,
  isRegularMode,
  normalizeCoins,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_TIME_OUT_BANNER,
  GameViewObjectPriorities,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  SlotMachineState,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuButton from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { IWinLine, Icon } from './d';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import MoleSlot from './mole/moleSlot';
import MoleSlotsContainer from './mole/moleSlotsContainer';
import Phoenix from './phoenix/phoenix';
import SafeArea from './safeArea/safeArea';
import { SCENE_CHANGE_FADE_TIME } from './sceneChange/config';
import SceneChange from './sceneChange/sceneChange';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;
  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public menuBtn: MenuButton;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public miniPayTableContainer: MiniPayTableContainer;

  public moleSlotsContainer: MoleSlotsContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public sceneChange: SceneChange;

  public infoBuyFeatureIcon?: BuyFeatureBtnIcon; //PIXI.Container;

  private lastChainCount = 0;

  private duringChain = false;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition.slice(0, 5));
    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    setReelSetId(reelSet.id);

    const spinResult = this.getInitSpinResult(startPosition, reelSet, slotConfig);
    setPrevSpinResult(spinResult);

    this.moleSlotsContainer = new MoleSlotsContainer();
    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      spinResult.map((icon) => icon.id),
      this.getChainLifeCounts(
        setUserLastBetChainData(),
        setUserLastBetChainInitialPayLines(),
        getSpinResult({
          reelPositions: startPosition.slice(0, 5),
          reelSet,
          icons: slotConfig.icons,
        }).map((icon) => icon.id),
        reelSet.type === ReelSetType.BONUS,
      ),
    );

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      slotsDisplayContainer: this.moleSlotsContainer,
      linesContainer: new LinesContainer(slotConfig.winLines),
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      lines: slotConfig.winLines,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.initBuyFeature();
    }

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());

      const initWait = Tween.createDelayAnimation(500);
      initWait.addOnComplete(() => {
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
      initWait.start();
    }

    this.menuBtn = new MenuButton();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    this.sceneChange = new SceneChange();

    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);

    this.application.stage.addChild(
      new Background(),
      safeArea,
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
      new BottomContainer(),
      new Phoenix(),
      this.sceneChange,
    );

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();

    if (setBrokenGame()) {
      this.onBrokenGame();
    }
  }
  private getLastSpinResult() {
    const nextResult = this.nextResult ?? setNextResult();
    if (
      nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons &&
      nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length
    ) {
      const chainCount = nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length;
      const slotIds = this.getChainResult(
        chainCount - 1,
        this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
        this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
      )?.spinResult!;
      const spinResult = slotIds
        .map((slotId) => setSlotConfig().icons.find((icon) => icon.id === slotId))
        .filter((icon): icon is Icon => icon !== undefined);
      return spinResult;
    }
    return this.nextResult!.bet.result.spinResult;
  }

  private getInitSpinResult(startPosition: number[], reelSet: ReelSet, slotConfig: ISlotConfig): Icon[] {
    const initSpinResult = getSpinResult({
      reelPositions: startPosition.slice(0, 5),
      reelSet,
      icons: slotConfig.icons,
    });

    if (setUserLastBetChainData().replacedIcons && setUserLastBetChainData().replacedIcons.length) {
      const chainData = setUserLastBetChainData();
      const { spinResult } = this.getChainResult(
        chainData.replacedIcons.length - 1,
        chainData,
        initSpinResult.map((icon) => icon.id),
      )!;
      const icons = spinResult.map((slotId) => slotConfig.icons.find((icon) => icon.id === slotId));

      return icons.filter((icon): icon is Exclude<typeof icon, undefined> => icon !== undefined);
    }

    return initSpinResult;
  }

  private initBuyFeature(): void {
    const buyFeatureBtn = new BuyFeatureBtn();
    const backDrop = new Backdrop();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();

    buyFeatureBtn.zIndex = GameViewObjectPriorities['BUY_FEATURE_BUTTON'];
    backDrop.zIndex = GameViewObjectPriorities['BUY_FEATURE_BACKDROP'];
    buyFeaturePopup.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP'];
    buyFeaturePopupConfirm.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP_CONFIRM'];

    this.gameView.addChild(buyFeatureBtn, backDrop, buyFeaturePopup, buyFeaturePopupConfirm);
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setCurrentBonusId(setCurrentBonus().id);
    setReelSetId(setCurrentBonus().reelSetId);

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (
      setUserLastBetResult().reelSetId === reelSets[GameMode.REGULAR] ||
      setUserLastBetResult().reelSetId === reelSets[GameMode.BUY_FEATURE]
    ) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('freeSpinsTitle'),
        description: i18n.t('freeSpinsText', {
          spin: setCurrentBonus().rounds,
        }),
        btnText: i18n.t('pressToStart'),
        callback: () => {
          this.setState(SlotMachineState.IDLE);
        },
      });
    } else {
      this.setState(SlotMachineState.IDLE);
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(
      EventTypes.ROLLBACK_REELS,
      setPrevSpinResult().map((icon) => icon.id),
    );
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    const spinResult = setPrevSpinResult().map((icon) => icon.id);
    eventManager.emit(EventTypes.ROLLBACK_REELS, spinResult);
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
      const spinResult = getSpinResult({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      setPrevSpinResult(spinResult);

      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(
        EventTypes.SHOW_STOP_SLOTS_DISPLAY,
        spinResult.map((icon) => icon.id),
      );
      setPrevReelsPosition(settings.reelPositions.slice(0, 5));
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();
      if (!bonus) throw new Error('Some went wrong');

      setCurrentBonusId(setCurrentBonus().id);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('freeSpinsTitle'),
          description: i18n.t('freeSpinsText', {
            spin: this.nextResult?.bet.data.bonuses[0]!.rounds,
          }),
          btnText: i18n.t('pressToStart'),
          callback: () => {
            //if (AudioApi.isRestricted) {
            //  BgmControl.handleChangeRestriction();
            //}
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private startFreeSpins(): void {
    const getBonus = this.getBonusFromResult()!;

    setCurrentBonus({
      ...getBonus,
      isActive: true,
      currentRound: 0,
    });
    setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
    setIsFreeSpinsWin(true);

    const animationChain = new AnimationChain();
    {
      const sceneChange = Tween.createDelayAnimation(1000);
      sceneChange.addOnComplete(() => {
        eventManager.emit(EventTypes.SCENE_CHANGE_DOWN, () => {
          eventManager.emit(EventTypes.CHANGE_MODE, {
            mode: GameMode.FREE_SPINS,
            reelPositions: [0, 0, 0, 0, 0],
            reelSetId: reelSets[GameMode.FREE_SPINS]!,
          });
          eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, 0, true);
        });
      });
      animationChain.appendAnimation(sceneChange);
    }
    animationChain.start();
  }

  private async endFreeSpins(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });

    const { reelSetId, reelPositions } = {
      reelSetId: bet.data.bet.reelSetId,
      reelPositions: bet.data.bet.result.reelPositions,
    };

    AudioApi.play({ type: ISongs.SONG_025_04_TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    const callback = () => {
      eventManager.emit(EventTypes.SCENE_CHANGE_UP, () => {
        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.REGULAR,
          reelPositions,
          reelSetId,
        });
      });

      setTimeout(() => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }, 100);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );
      }, SCENE_CHANGE_FADE_TIME);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('youWon'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('youWon'),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.moleSlotsContainer.spinAnimations[0]!.getRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, current.toString(), total.toString(), false);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);

          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getScatterCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);

      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      setScatterPositions([]);

      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!!isTurboSpin);

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, bonus.currentRound, false);
        setCurrentBonus(bonus);
      }

      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private isSetOfFiveWilds() {
    if (!this.nextResult?.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines.length) {
      return false;
    }

    const fivePieceSetPayLines =
      this.nextResult?.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines.filter(
        (v) => v.winPositions.length >= 5,
      );

    if (fivePieceSetPayLines.length === 0) return false;

    const spinResult = this.nextResult.bet.result.spinResult;
    let isFiveWilds = false;

    fivePieceSetPayLines.forEach((v) => {
      let count = 0;
      v.winPositions.forEach((position) => {
        if (spinResult[position]?.id === SlotId.WL) {
          count += 1;
        }
      });
      if (count >= 5) {
        isFiveWilds = true;
      }
    });

    return isFiveWilds;
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    const spinAnimations = this.moleSlotsContainer.createSpinAnimation(isTurboSpin);
    spinAnimations.forEach((animation) => {
      animationGroup.addAnimation(animation);
    });

    spinAnimations[0]!.getRolling().addOnChange(() => {
      if (this.nextResult && !this.isReadyForStop) {
        this.isReadyForStop = true;
        if (isFreeSpinMode(setGameMode())) {
          this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
        }
        this.removeErrorHandler();
        this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);

        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.result.reelPositions,
          this.getScatterCount(this.nextResult.bet.result.spinResult),
          this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
        );
      }
    });

    spinAnimations[0]!.getRolling().addOnComplete(this.throwTimeoutError);

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  private isGetBonus(): boolean {
    return !!(
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  private getCurrentChain() {
    const chainCount =
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length;
    return this.getChainResult(
      chainCount - (this.lastChainCount + 1),
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
      this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
    );
  }

  private getChainResult(chainIndex = 0, chainData: IChainData, symbols: SlotId[]) {
    const chainResults: {
      spinResult: SlotId[];
      paylines: IWinLine[];
    }[] = [];

    let slotIds = symbols;
    const allReplacedIcons = chainData.replacedIcons;
    const allPaylines = chainData.paylines;
    let index = 0;

    while (index < allReplacedIcons.length) {
      const replacedIcons = allReplacedIcons[index]!;
      const replacedPositions = Object.keys(replacedIcons).map((value) => Number(value));
      slotIds = slotIds.map((slotId, position) => {
        if (replacedPositions.includes(position)) {
          return replacedIcons[position]!.target as SlotId;
        }
        return slotId;
      });

      let paylines: IWinLine[] = [];
      if (allPaylines && index < allPaylines.length) {
        const chainPaylines: IWinLine[] = allPaylines[index]!.map((p) => {
          const amount = p.rewards.reduce((sum, reward) => sum + reward.multiplier * setCoinAmount(), 0);
          return { lineId: p.lineId, amount, winPositions: p.winPositions };
        });
        paylines = chainPaylines;
      }
      chainResults.push({ spinResult: slotIds, paylines });

      index++;
    }

    return chainResults[chainIndex];
  }

  private getInitLifeCounts(initialPaylines: IChainInitialPayLines[], symbols: SlotId[], isResumeFreeSpin = false) {
    if (!isFreeSpinMode(setGameMode()) && !isResumeFreeSpin) {
      return Array<number>(REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT).fill(0);
    }
    const winPositions = Array.from(
      new Set(
        this.getFirstWinPaylines(initialPaylines).flatMap((p) => {
          return p.winPositions;
        }),
      ),
    );

    const lifeCounts = symbols.map((id, position) => {
      if (id === SlotId.WL) {
        if (winPositions.includes(position)) {
          return 3 - 1;
        }
        return 3;
      }
      return 0;
    });

    return lifeCounts;
  }

  private getChainLifeCounts(
    chainData: IChainData,
    initialPaylines: IChainInitialPayLines[],
    symbols: SlotId[],
    isResumeFreeSpin = false,
  ) {
    const chainCount = chainData.replacedIcons.length;
    const chainIndex = chainCount - this.lastChainCount;
    const initLifeCounts = this.getInitLifeCounts(initialPaylines, symbols, isResumeFreeSpin);
    const lifeCountsHistory: number[][] = [];

    lifeCountsHistory.push(initLifeCounts);

    let index = 0;
    while (index < chainCount) {
      const chainResult = this.getChainResult(index, chainData, symbols)!;
      const winPositions = Array.from(
        new Set(
          chainResult.paylines.flatMap((p) => {
            return p.winPositions;
          }),
        ),
      );
      const prevLifeCounts = lifeCountsHistory[index]!;
      const lifeCounts = chainResult!.spinResult.map((slotId, position) => {
        if (slotId === SlotId.WL && (isFreeSpinMode(setGameMode()) || isResumeFreeSpin)) {
          if (winPositions.length && winPositions.includes(position)) {
            if (prevLifeCounts[position]! > 0) {
              return prevLifeCounts[position]! - 1;
            } else {
              return 3 - 1;
            }
          }
          return prevLifeCounts[position]! == 0 ? 3 : prevLifeCounts[position]!;
        } else {
          return 0;
        }
      });
      lifeCountsHistory.push(lifeCounts);
      index++;
    }

    return lifeCountsHistory[chainIndex]!;
  }

  private isTriggerFreeSpinFeature() {
    if (!isFreeSpinMode(setGameMode()) && this.isGetBonus()) {
      return true;
    }
    return false;
  }

  private getScatterPositions() {
    const positions: number[] = [];
    if (this.isTriggerFreeSpinFeature()) {
      this.nextResult!.paylines.filter((p) => p.lineId === null)
        .flatMap((p) => {
          return p.winPositions;
        })
        .forEach((position) => positions.push(position));
    }
    return positions;
  }

  private startChainSpin() {
    const chainCount =
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length;
    const isFirstWin = this.lastChainCount === chainCount;
    const prevWinPosition = [
      ...new Set(
        isFirstWin
          ? this.getFirstWinPaylines(
              this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
            )
              .filter((p) => p.lineId !== null)
              .flatMap((p) => {
                return p.winPositions;
              })
          : this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.paylines[
              chainCount - this.lastChainCount - 1
            ]!.flatMap((p) => {
              return p.winPositions;
            }),
      ),
    ];

    const prevLifeCounts = isFirstWin
      ? this.getInitLifeCounts(
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
          this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
        )
      : this.getChainLifeCounts(
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
          this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
        );
    const resultChain = this.getChainResult(
      chainCount - this.lastChainCount,
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
      this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
    )!;
    const spinResult = resultChain!.spinResult;

    const animation = new AnimationChain();
    const chainSpinAnimation = this.moleSlotsContainer.createChainSpinAnimation(
      spinResult,
      prevWinPosition,
      prevLifeCounts,
    );

    chainSpinAnimation.addOnComplete(() => {
      const winPositions = [
        ...new Set(
          resultChain.paylines.flatMap((p) => {
            return p.winPositions;
          }),
        ),
      ];
      eventManager.emit(EventTypes.SHOW_CHAIN_STOP_SLOTS_DISPLAY, spinResult, winPositions);

      this.setState(SlotMachineState.JINGLE);
    });
    // next chain waiting
    animation.appendAnimation(Tween.createDelayAnimation(1500));

    animation.appendAnimation(chainSpinAnimation);

    animation.start();
  }

  private updateWinAmount(winAmount = 0) {
    if (isFreeSpinMode(setGameMode())) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      setWinAmount(setWinAmount() + winAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      if (winAmount > 0 && !this.isTriggerFreeSpinFeature()) {
        const amount = this.nextResult!.balance.placed.amount + setWinAmount() * setCoinValue();
        const currency = this.nextResult!.balance.settled.currency;
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, {
          currency,
          amount: amount < this.nextResult!.balance.settled.amount ? amount : this.nextResult!.balance.settled.amount,
        });
      }
    }
  }

  private onCountUpEnd(): void {
    if (!this.duringChain) {
      const replacedIcons = this.nextResult?.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons;
      if (replacedIcons && replacedIcons.length > 0) {
        // start chain
        const winAmount = this.getFirstWinAmount(
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
        );
        this.lastChainCount = replacedIcons.length;
        this.duringChain = true;
        this.updateWinAmount(winAmount);
      } else if (this.isTriggerFreeSpinFeature()) {
        // only scatter win
        const winAmount = this.getFirstWinAmount(
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
        );
        this.updateWinAmount(winAmount);
      }
    } else {
      // end chain
      if (this.lastChainCount === 0) {
        this.duringChain = false;
      }
      const chain = this.getCurrentChain();
      const winAmount = chain!.paylines ? chain!.paylines.reduce((sum, p) => sum + p.amount!, 0) : 0;
      this.updateWinAmount(winAmount);
    }

    if (this.duringChain) {
      this.setState(SlotMachineState.CHAIN);
      this.lastChainCount -= 1;
      return;
    }

    const mode = setGameMode();
    if (!isFreeSpinMode(mode) && this.isGetBonus()) {
      this.startFreeSpins();
    }
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(reelId: string): void {
    if (setReelSetId() !== reelId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.onSpinStop(isTurboSpin);
    setIsBuyFeaturePurchased(false);
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    if (setGameMode() === GameMode.FREE_SPINS) return REELS_AMOUNT;

    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME[i];
      let currentCount = 0;
      for (let reelId = 0; reelId < REELS_AMOUNT; reelId++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          if (spinResult[reelId + REELS_AMOUNT * i]!.id === symbolId) {
            currentCount += 1;
          }
        }
        if (currentCount >= count! && reelId + 1 < REELS_AMOUNT) {
          minReelId = Math.min(minReelId, 3);
        }
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Icon[]): number[] {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (!setIsDuringWinCountUpAnimation()) {
      if (this.state === SlotMachineState.IDLE || this.state === SlotMachineState.WINNING) {
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      }
    }
  }

  private getUserBonusId(result: ISettledBet): string {
    return (result.rewards.find((reward) => reward.__typename === 'BetBonusReward') as BetBonusReward).userBonusId;
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
      icons: setSlotConfig().icons,
    });

    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 5));

    this.nextResult = result;
    setNextResult(result);

    setPrevSpinResult(this.getLastSpinResult());

    const gameMode = setGameMode();
    if (isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    }

    if (this.isSetOfFiveWilds() && getRandomNumber(100) >= 50) {
      eventManager.emit(EventTypes.PHOENIX_START);
    }

    console.info(this.nextResult);
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    if (
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons &&
      this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length
    ) {
      const chainCount =
        this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData.replacedIcons.length;
      const slotIds = this.getChainResult(
        chainCount - 1,
        this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
        this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
      )?.spinResult!;
      const spinResult = slotIds
        .map((slotId) => setSlotConfig().icons.find((icon) => icon.id === slotId))
        .filter((icon): icon is Icon => icon !== undefined);
      this.miniPayTableContainer.setSpinResult(spinResult);
    } else {
      this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    }

    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): MoleSlot | null {
    return this.moleSlotsContainer.slots[y * REELS_AMOUNT + x]!;
  }

  public getSlotById(id: number): MoleSlot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private getFirstWinPaylines(initialPaylines: IChainInitialPayLines[]) {
    const paylines = initialPaylines.map((p) => {
      if (p.payoffType === 'LTR') {
        const amount = p.rewards.reduce((sum, reward) => sum + reward.multiplier * setCoinAmount(), 0);
        return { lineId: p.lineId, amount, winPositions: p.winPositions };
      } else {
        // TODO There is no "amount" in initialPayline, so this calculation must be used to calculate "amount".
        const amount = p.rewards[0]!.multiplier * setBetAmount();
        return { lineId: null, amount: amount, winPositions: p.winPositions };
      }
    }) as IWinLine[];
    return paylines;
  }

  private getFirstWinAmount(initialPaylines: IChainInitialPayLines[]) {
    const paylines = this.getFirstWinPaylines(initialPaylines);
    if (paylines && paylines.length) {
      const winAmount = paylines.reduce((sum, data) => {
        return sum + data.amount;
      }, 0);
      return winAmount;
    }
    return 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature() || setIsFadeOut(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          const endDelay = Tween.createDelayAnimation(1000);
          endDelay.addOnComplete(() => this.endFreeSpins());
          endDelay.start();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (setIsContinueAutoSpinsAfterFeature()) {
        this.skipAnimations();
        setTimeout(() => eventManager.emit(EventTypes.SPACE_KEY_SPIN), 500);
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }

    if (state === SlotMachineState.JINGLE) {
      const jingleDelay = new AnimationChain();

      if (!this.duringChain) {
        if (this.isTriggerFreeSpinFeature()) {
          const featureTriggerDelay = Tween.createDelayAnimation(1000);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.SONG_FeatureTrigger });
          });
          jingleDelay.appendAnimation(featureTriggerDelay);

          setScatterPositions(this.getScatterPositions());
        }
        // first base win delay
        if (this.hasWin()) {
          const delayToHammer = Tween.createDelayAnimation(500);
          const hammerAnimationEvent = Tween.createDelayAnimation(1000);
          const symbols = this.nextResult!.bet.result.spinResult.map((icon) => icon.id);
          const initLifeCounts = this.getInitLifeCounts(
            this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
            symbols,
          );
          const paylines = this.getFirstWinPaylines(
            this.nextResult!.bet.data!.features.gameRoundStore.chainFeatureStore.initialPaylines,
          );
          hammerAnimationEvent.addOnStart(() => {
            eventManager.emit(EventTypes.START_HIT_HAMMER_ANIMATION, symbols, paylines, initLifeCounts);
          });
          jingleDelay.appendAnimation(delayToHammer);
          jingleDelay.appendAnimation(hammerAnimationEvent);
        }
      } else {
        // chain delay
        const chainResult = this.getCurrentChain();
        const lifeCounts = this.getChainLifeCounts(
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
          this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
          this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
        );

        if (chainResult!.paylines && chainResult!.paylines.length) {
          const hammerAnimationEvent = Tween.createDelayAnimation(1000);
          hammerAnimationEvent.addOnStart(() => {
            eventManager.emit(
              EventTypes.START_HIT_HAMMER_ANIMATION,
              chainResult!.spinResult,
              chainResult!.paylines,
              lifeCounts,
            );
          });
          jingleDelay.appendAnimation(hammerAnimationEvent);
        }
      }

      if (jingleDelay.animations.length) {
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }

    if (state === SlotMachineState.WINNING) {
      let paylines = this.getFirstWinPaylines(
        this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
      );
      let winAmount = paylines.reduce((sum, d) => sum + d.amount, 0);
      let lifeCounts = this.getInitLifeCounts(
        this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
        this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
      );

      if (this.duringChain) {
        if (this.lastChainCount > 0) {
          const currentChain = this.getCurrentChain();
          winAmount = currentChain!.paylines.reduce((sum, d) => sum + d.amount, 0);
          paylines = currentChain!.paylines;
          lifeCounts = this.getChainLifeCounts(
            this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.chainData,
            this.nextResult!.bet.data.features.gameRoundStore.chainFeatureStore.initialPaylines,
            this.nextResult!.bet.result.spinResult.map((icon) => icon.id),
          );
        } else {
          winAmount = 0;
        }
      }

      if (winAmount > 0) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, paylines, winAmount, lifeCounts);
      } else {
        this.onCountUpEnd();
      }
    }

    if (state === SlotMachineState.CHAIN) {
      this.startChainSpin();
    }
  }
}

export default SlotMachine;
