import * as PIXI from 'pixi.js';

import { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/query';
import Animation from './slotMachine/animations/animation';
import { IWinLine, Icon, LineSet } from './slotMachine/d';

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_NAME: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface IChainData {
  paylines: {
    lineId: number;
    pattern: string;
    payoffType: string;
    rewards: {
      type: string;
      multiplier: number;
    }[];
    type: string;
    winPositions: number[];
  }[][];
  replacedIcons: Record<number, { source: string; target: string; isRetained?: boolean }>[];
}

export interface IChainInitialPayLines {
  lineId: number | null;
  pattern: string;
  payoffType: string;
  rewards: {
    type: string;
    multiplier: number;
  }[];
  type: string;
  winPositions: number[];
}

export interface IGameRoundStore {
  chainFeatureStore: {
    chainData: IChainData;
    initialPaylines: IChainInitialPayLines[];
  };
  stickyWildsStore: {
    [key: number]: {
      lifeCount: number;
    };
  };
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    status: string;
    userBonusId: string;
    reelSetId: string;
    data: {
      bonuses: UserBonus[];
      features: {
        gameRoundStore: IGameRoundStore;
      };
    };
    createdAt: string;
    updatedAt: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      //undefined
      spinResult: Icon[];
    };
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  id: string;
  bonusId: string;
  coinValue: number;
  coinAmount: number;
  data: UserBonusData;
  betId: string;
  status: string;
  rounds: number;
  roundsPlayed: number;
  totalWinAmount: number;
  bonus: Bonus;
  currentRound: number;

  // undefined
  isActive: boolean;
  reelSetId: string;
};

export type Bonus = {
  coinAmount: number;
  data: unknown;
  description?: string;
  id: string;
  purchasable: boolean;
  reelSetId: string;
  rounds: number;
  title?: string;
  type: BonusType;

  // undefined
  slotId: string;
  lineSetId: string | null;
};

export type UserBonusData = {
  count: number;
  maxRounds: number;
  storeCoinValue: boolean;
  debitMultiplier: number;
  storeCoinAmount: boolean;
  creditMultiplier: number;

  // undefined
  betId: string;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
  preloadGrands: number[];
};

export type GrantsReward = CoinGrants | BonusGrants;

export type PreLoadedGrantBreakdown = {
  grants: Grants[];
  trigger: Trigger;
};

export type Grants = {
  grants: GrantsReward[];
  type: string;
};

export type CoinGrants = {
  multiplier: number;
  rewardBasis: string;
  type: string;
};

export type BonusGrants = {
  count: number;
  id: string;
  type: string;
};

export type Trigger = {
  triggerId: string;
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type MessageBannerProps = {
  title?: { key: string; var: string };
  titlePosition?: number;
  titleStyles?: PIXI.TextStyle;
  subtitle?: { key: string; var: string };
  subtitlePosition?: number;
  subtitleStyles?: PIXI.TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: PIXI.TextStyle;
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
  callback?: () => void;
  preventDefaultDestroy: boolean;
};

export enum EventTypes {
  START_ANTICIPATION_SLOT = 'startAnticipationSlot',
  END_ANTICIPATION_SLOT = 'endAnticipationSlot',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_CHAIN_STOP_SLOTS_DISPLAY = 'showChainStopSlotsDisplay',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACE_KEY_SPIN = 'spaceKeySpin',
  SPACE_KEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESIZE = 'resize',
  RESIZE_UI_BUTTON = 'resizeUiButton',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_HIT_HAMMER_ANIMATION = 'startHammerHitAnimations',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_CHAIN_SPIN_ANIMATION = 'startChainSpinAnimation',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  FORCE_CLOSE_BUY_FEATURE = 'forceCloseBuyFeature',
  SET_IS_SUSPENDED = 'setIsSuspended',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_FADEOUT = 'setIsFadeOut',
  UPDATE_FREE_SPINS_COUNT = 'updateFreeSpinsCount',
  SCENE_CHANGE_DOWN = 'sceneChangeDown',
  SCENE_CHANGE_UP = 'sceneChangeUp',
  PHOENIX_START = 'phoenixStart',
}

export interface Events {
  [EventTypes.START_ANTICIPATION_SLOT]: (reelId: number, slotIndex: number, duration: number) => void;
  [EventTypes.END_ANTICIPATION_SLOT]: (reelId: number, slotIndex: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACE_KEY_SPIN]: () => void;
  [EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (spinResult: SlotId[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_HIT_HAMMER_ANIMATION]: (spinResult: SlotID[], paylines: IWinLine[], lifeCounts?: number[]) => void;
  [EventTypes.START_WIN_ANIMATION]: (paylines: IWinLine[], winAmount: number, lifeCounts?: number[]) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_CHAIN_SPIN_ANIMATION]: () => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: SlotId[], lifeCounts?: number[]) => void;
  [EventTypes.SHOW_CHAIN_STOP_SLOTS_DISPLAY]: (spinResult: SlotId[], winPositions: number[]) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_SIXTH_REEL_POSITIONS]: (sixReelResult: SixthReelResult) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: string, curr: string, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.SCENE_CHANGE_DOWN]: (changeModeCallBack?: () => void) => void;
  [EventTypes.SCENE_CHANGE_UP]: (changeModeCallBack?: () => void) => void;
  [EventTypes.UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.FORCE_CLOSE_BUY_FEATURE]: () => void;
  [EventTypes.RESIZE_UI_BUTTON]: (width: number, height: number) => void;
  [EventTypes.SET_IS_SUSPENDED]: (isSuspended: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenMessageBanner: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterStopCount: number[],
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SET_IS_FADEOUT]: (boolean) => void;
  [EventTypes.PHOENIX_START]: () => void;
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS = 'fs',
}

export enum GameMode {
  REGULAR,
  FREE_SPINS,
  BUY_FEATURE,
}
export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPINS]: '318a9ce2-1736-40de-8a3b-071bbd401e59',
  [GameMode.BUY_FEATURE]: 'e79c3c98-97dc-4156-b45b-5a691da02829',
};

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '9b8f423c-433e-4810-9ffa-8283b958ddd3',
  [GameMode.FREE_SPINS]: '82e28210-d181-48a4-bf94-af88f4e19b0b',
  [GameMode.BUY_FEATURE]: '9a2f7d45-cd31-4a23-8b70-40cde700d439',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: 'f0d4c480-f67d-4803-a406-cd0d23d1c18c',
  [GameMode.FREE_SPINS]: 'f0d4c480-f67d-4803-a406-cd0d23d1c18c',
  [GameMode.BUY_FEATURE]: 'f0d4c480-f67d-4803-a406-cd0d23d1c18c',
};
